.top{
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}
.top-left{
    width: 35%;
}
.top-rigth{
    width: 65%;
}
.info-text{
    margin-top: 2.5rem;
    font-size: x-large;
    font-weight: 400;
    text-decoration: underline rgb(103, 100, 100) 1px;
}
.cibil-day{
    border: 1px solid #ccc;
    padding: 1%;
    margin-top: 3%;
    margin-bottom: 2%;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.4);
}
.table{
    border: 1px solid #ccc;
    margin-top: 5%;
    margin-bottom: 10%;
    border-radius: 6px;
    color: #001C61 !important;
}
.table-body{
    color: #001C61 !important;
    background-color: rgba(255, 255, 255, 0.4);
}

.emp-table{
    width: 60%;
}
.td{
    font-size: 13px;
    color: #001C61 !important;
    font-weight: 400;
}