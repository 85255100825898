.card-main-div{
    border: 1px solid #ccc;
    display: flex;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    width: 75%;
    margin: auto;
    padding: 1rem;
}

.card-main-div>div{
    margin: 10px;
}

.img-card{
    width: 20%;
}
.img-card>img{
    width: 50%;
}
.img-card>h2{
    margin-top: 1rem;
    font-size: medium;
    font-weight: 500;
    color: rgb(51, 51, 51);
}
.card-main-div p{
    font-weight: 400;
    color: rgb(51, 51, 51);
}
.app-btn{
    padding: 10px 15px;
}
.card-main-div button{
    background-color: orange;
    padding: 10% 30%;
    color: white;
    border-radius: 8px;
    font-weight: 500;
}
.app-btn>h3{
    color: rgba(0, 106, 255, 0.648);
    text-decoration: underline;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}
.app-btn>h3:hover{
    cursor: pointer;
}


@media screen and (max-width: 700px){
    .card-main-div{
        display: flex;
        flex-direction: column;
        line-height: 30px;
    }
    .img-card{
        width: 60%;
    }
    .app-btn{
        width: 35%;
        margin: auto;
    }
}