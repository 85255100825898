

body{
  height: 84vh !important;
}

*::-webkit-scrollbar {
  width: 13px;
}
*::-webkit-scrollbar-track {
  background: rgb(216, 216, 216);
  background: #00000014;
  /* border-radius: 10px; */
}
*::-webkit-scrollbar-thumb {
  /* background:#0bc5ea47; */
  /* background: #bbb; */
  background: #32a8eda5;
}
.active{
  /* background: #F6D727; */
  background: #0094e9c2;
  color: #fff;
}
p{
  color: #333 !important;
}

a{
  color: #333;
}
.icon{
display: block;
margin: auto;
width: 30px;
margin-bottom: 10px;
/* filter: invert(); */
}

.nav-link{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px 10px;
  font-size: 18px;

  background-color: #4ab3eb;

}
.logoName{
background-color:honeydew;
font-size: 23px;
}


.active-acc-tab{
color: #fff !important;
background: #23AAF2 !important;
}

@media screen and (max-width: 800px) {
#root > div > div:nth-child(1) > nav > div> a{
  font-size: 14px;
}
}
@media screen and (max-width: 700px) {
 
#root > div > div:nth-child(1) > nav > div> a{
font-size: 12.5px;
padding: 5px 10px;
}
#root > div > div:nth-child(1) > nav > div.css-7lbs7f > a{
display: none;
}

#root .card-main-div{
all: initial;
width: 80%;
padding: 10px;
/* background-color: red ; */
border: solid 1px #ccc;
border-radius: 6px;
margin: 1rem auto;
display: flex;
align-items: center;
justify-content: center;
text-align: left;
font-size: 12px;
}
#root > div > div:nth-child(2) > div:nth-child(1) > div.chakra-stack.css-1i4i9dm > div.css-11pip87{
border-right: none;
}
.login-img,.signup-img{
display: none;
}

#root .admin-right.css-1hu07q0 .item{
margin-left: 1rem;
grid-template-columns: repeat(3, 200px) !important;
justify-content: center;
background: green;
flex-wrap: wrap !important;
gap: 20px 0;
}


#root .filters{
width: 400px !important;
}

#root .admin-right{
margin-left: 0 !important;
width: 100%;
}


}